<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner pt-6">
      <v-card class="px-8 py-12">
        <v-row>
          <v-col cols="3">
            <v-img src="../../assets/images/logos/LogoOrange.png" max-width="40px"></v-img>
          </v-col>
          <v-col cols="9">
            <div
              class="d-flex justify-center"
              style="font-size: 25px; font-weight: bold; margin-left: -80px; margin-top: 3px"
            >
              <span>ログイン</span>
            </div>
          </v-col>
        </v-row>

        <div class="my-4">
          <span>お客様のアカウントでログイン</span>
        </div>
        <v-alert v-show="errorMessages" color="red" dense type="error">
          {{ errorMessages }}
        </v-alert>
        <v-text-field
          v-model="$v.email.$model"
          placeholder="メールアドレス"
          hide-details="auto"
          outlined
          dense
          :error-messages="emailErrors"
          color="primary"
        />
        <v-text-field
          v-model="password"
          class="mt-5"
          :type="isPasswordVisible ? 'text' : 'password'"
          :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
          placeholder="パスワード"
          hide-details="auto"
          outlined
          dense
          color="primary"
          :error-messages="passwordErrors"
          @click:append="isPasswordVisible = !isPasswordVisible"
          @keydown.enter="login"
        />
        <div style="max-height: 50px">
          <v-checkbox v-model="rememberMe" hide-details label="ログイン状態を保持します" color="primary" />
        </div>
        <v-row class="mt-3" style="height: 45px" no-gutters>
          <v-col cols="8">
            <div class="pointer">
              <span style="text-decoration: underline; color: #ff6e40" @click="$router.push('/forgot-password')">
                パスワード忘れた場合
              </span>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex justify-end">
            <div class="pointer">
              <span style="text-decoration: underline; color: #ff6e40" @click="$router.push('/register')">
                新規登録
              </span>
            </div>
          </v-col>
        </v-row>
        <v-btn depressed block color="primary" :loading="loading" @click="login"> ログイン </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
import agent from '@/api/agent'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { email, required } from 'vuelidate/lib/validators'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Base64 } from 'js-base64'

export default {
  data: () => ({
    email: '',
    password: '',
    isPasswordVisible: false,
    errorMessages: '',
    loading: false,
    rememberMe: false,
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
  }),
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      // eslint-disable-next-line
      !this.$v.email.required && errors.push('メールアドレスを入力してください。')
      // eslint-disable-next-line
      !this.$v.email.email && errors.push('メールアドレスを正しく入力してください。')

      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      // eslint-disable-next-line
      !this.$v.password.required && errors.push('パスワードを入力してください。')

      return errors
    },
  },
  mounted() {
    this.loadAccountInfo()
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    loadAccountInfo() {
      const account = JSON.parse(localStorage.getItem('account'))
      if (account) {
        this.email = account.email
        this.password = Base64.decode(account.password)
        this.rememberMe = true
      }
    },
    login() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.loading = true
        agent.Auth.login({
          email: this.email,
          password: this.password,
        })
          .then(response => {
            const { token } = response.data.data
            this.$store.dispatch('auth/setAuthStatus', true)
            this.$store.dispatch('auth/updateToken', token)
            this.$store.dispatch('auth/loadInfo')
            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect)
            } else {
              this.$router.push('/')
            }

            if (this.rememberMe) {
              const pwd = Base64.encode(this.password) // base64加密
              const account = {
                email: this.email,
                password: pwd,
              }
              localStorage.setItem('account', JSON.stringify(account))
            } else {
              localStorage.removeItem('account')
            }

            // return response
          })
          .catch(error => {
            // TODO: Next Update - Show notification
            const { code, message } = error.response.data

            if (code === 400) {
              const msg = message[0]
              this.errorMessages = msg
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.pointer :hover {
  cursor: pointer;
}
</style>
